<template>
  <base-section id="about-us">
    <base-section-heading
      color="grey lighten-2"
      title="Visi dan Misi"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="6"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutUs',

    data: () => ({
      cards: [
        {
          title: 'Visi',
          text: 'Menjadi Mitra Penjaminan Terpercaya Bagi Pelaku Usaha Dalam Mendukung Perkembangan Bisnis Yang Sehat',
          callout: '01',
        },
        {
          title: 'Misi',
          text: 'Melakukan Kegiatan Penjaminan Secara Profesional Dengan Layanan Prima Bagi Para Pelaku Usaha Menciptakan Produk Penjaminan Yang Inovatif dan Kreatif',
          callout: '02',
        },
      ],
    }),
  }
</script>
